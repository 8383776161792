import * as React from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BungalowIcon from '@mui/icons-material/Bungalow';
import GavelIcon from '@mui/icons-material/Gavel';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ModalImmo() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleClick = event => {
        const element = document.getElementById('servOptInfoErb');
        element.classList.add('visible');
      };

    return (
        <div>


            <div className="ctaWrapper fadeInOpac">
                <Button onClick={handleOpen} variant='contained' className='btnSecondary2'>Zum Online Formular<TrendingFlatIcon className='secondaryBtnIcon'/></Button>
            </div> 


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modalInnerBox' sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='modalTitle'>
                        Welche Leistung wollen Sie in Anspruch nehmen?
                    </Typography>

                    <Grid container justifyContent="center" spacing={2} className='modalServiceGrid'>

                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/inherit" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><HistoryEduIcon className='dialogeleIcon'/>
                            Erbscheinsanträge</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/purchase" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><BungalowIcon className='dialogeleIcon'/>Immobilienkaufvertrag</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/founding" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><ApartmentIcon className='dialogeleIcon'/>Firmengründung</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/companychange" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><ApartmentIcon className='dialogeleIcon'/>Firmenänderung</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/registeredassociation/founding" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><Diversity3Icon className='dialogeleIcon'/>Vereinsgründung</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/registeredassociation/change" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><Diversity3Icon className='dialogeleIcon'/>Vereinsänderung</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <Button href="https://dialog.bensch-notar.de/mortgage" target='_blank' variant='contained' className='servOptBtn'><div class="servOptBtnSubWrapper"><GavelIcon className='dialogeleIcon'/>Grundschuld</div><TrendingFlatIcon className='secondaryBtnIcon' /></Button>
                        </Grid>


                        <Grid item xs={12} sm={12} className='smallGridItem'>
                            <p>Für weitere Dinstlistungen kontaktiern Sie uns. Nutzen sie hierfür ganz einfach unser <a href="/#kontakt" onClick={handleClose}>Kontaktformular</a>.</p>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}